@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');
html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Quicksand", sans-serif;
    font-size: 62.5%;
    font-size: 10px;
    background-color: #121212;
}

body{
    color: white;
}

.noDisplay{
    display: none;
}

.mainHome{
    width: 100%;
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}

.mainHome *{
    max-width: 800px;
}

.mainHome h2{
    font-size: 5rem;
    font-family: Arial, sans-serif;
}

.mainHome h3{
    font-size: 2.5rem;
    font-family: Arial, sans-serif;
    padding-top: 10px;
    padding-bottom: 10PX;
    border-top: #fff 2px solid;
    border-bottom: #fff 2px solid;
}

.mainHome h4{
    font-size: 3rem;
    padding-top: 10px;
}

.mainHome img{
    width: 100%;
}

@media screen and (max-width:768px) {
    .affix{
        padding-top: 20px;
    }

    .mainHome{
        padding-left: 10px;
        padding-right: 10px ;
    }
}

.slika{
    width: 100%;
}

.nopad{
    padding: 0;
}

.nomargin{
    margin: 0;
}

.ponuda{
    padding: 15px;
    display: flex;
    align-items: center;
}

.ponuda h2{
    text-align: center;
    font-size: 3.5rem;
    color: rgb(168, 3, 168);
}

.ponuda h3{
    text-align: center;
    font-size: 2.5rem;
    color: rgb(209, 157, 209);
}

.ponuda p{
    font-size: 2rem;
    text-align: center;
}

@media screen and (min-width:768px) {
    .ponuda{
        padding: 20px;
    }

    .ponuda h2{
        font-size: 2rem;
    }

    .ponuda h3{
        font-size: 1.5rem;
    }
    .ponuda p{
        font-size: 1.1rem;
    }
}

@media screen and (min-width:992px) {
    .ponuda{
        padding: 20px;
    }

    .ponuda h2{
        font-size: 2.7rem;
    }

    .ponuda h3{
        font-size: 2rem;
    }
    .ponuda p{
        font-size: 1.55rem;
    }
}

@media screen and (min-width:1200px) {
    .ponuda{
        padding: 20px;
    }

    .ponuda h2{
        font-size: 3rem;
    }

    .ponuda h3{
        font-size: 2.2rem;
    }
    .ponuda p{
        font-size: 1.85rem;
    }
}

@media screen and (min-width:1400px) {
    .ponuda{
        padding: 20px;
    }

    .ponuda h2{
        font-size: 3.3rem;
    }

    .ponuda h3{
        font-size: 2.5rem;
    }
    .ponuda p{
        font-size: 2.18rem;
    }
}





/* Navbar section */

.nav {
    width: 100%;
    height: 65px;
    position: fixed;
    line-height: 65px;
    text-align: center;
    z-index: 2;
}

.nav div.logo {
    float: left;
    width: auto;
    height: auto;
    padding-left: 3rem;
}

.nav div.logo a {
    text-decoration: none;
    color: #fff;
    font-size: 2.5rem;
}

.nav div.logo a:hover {
    color: #ce1010;
}

.nav div.main_list {
    height: 65px;
    float: right;
}

.nav div.main_list ul {
    width: 100%;
    height: 65px;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav div.main_list ul li {
    width: auto;
    height: 65px;
    padding: 0;
    padding-right: 3rem;
}

.nav div.main_list ul li a {
    text-decoration: none;
    color: #fff;
    line-height: 65px;
    font-size: 2.4rem;
}

.nav div.main_list ul li a:hover {
    color: #ce1010;
}


.navTrigger {
    display: none;
}

.nav {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}


.nav div.main_list ul {
    transition: max-height 0.3s ease; /* Add this line */
}

/* Media qurey section */

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .cont2 {
        margin: 0;
    }
}

@media screen and (max-width:768px) {
    .navTrigger {
        display: block;
    }
    .nav div.logo {
        margin-left: 15px;
    }
    .nav div.main_list {
        width: 100%;
        height: 0;
        overflow: hidden;
    }
    .nav div.show_list {
        height: auto;
        display: none;
    }
    .nav div.main_list ul {
        flex-direction: column;
        width: 100%;
        height: 100vh;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #111;
        /*same background color of navbar*/
        background-position: center top;
    }
    .nav div.main_list ul li {
        width: 100%;
        text-align: center;
    }
    .nav div.main_list ul li a {
        text-align: center;
        width: 100%;
        font-size: 3rem;
        padding: 20px;
    }
    .nav div.media_button {
        display: block;
    }

    .nav div.main_list ul {
        max-height: 0; /* Add this line to hide the menu initially */
        overflow: hidden;
    }

    .nav div.main_list.show_list ul {
        max-height: 100vh; /* Add this line to show the menu */
    }

    .nav div.main_list ul li {
        /* ... (existing styles) */
        opacity: 0; /* Add this line for fade-in effect */
        transform: translateY(-20px); /* Add this line for slide-in effect */
        transition: opacity 0.3s ease, transform 0.3s ease; /* Add this line for transition */
    }

    .nav div.main_list.show_list ul li {
        opacity: 1; /* Add this line for fade-in effect */
        transform: translateY(0); /* Add this line for slide-in effect */
    }

    .nav.show_menu div.main_list ul {
        max-height: 100vh;
      }
      
      .nav div.main_list ul li {
        /* ... (your existing styles) */
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 0.3s ease, transform 0.3s ease;
      }
      
      .nav.show_menu div.main_list ul li {
        opacity: 1;
        transform: translateY(0);
      }

      .logo{
        transform: translateY(-28%);
      }
    
}

.navTrigger {
    cursor: pointer;
    width: 30px;
    height: 25px;
    margin: auto;
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
}

.navTrigger i {
    background-color: #fff;
    border-radius: 2px;
    content: '';
    display: block;
    width: 100%;
    height: 4px;
}

.navTrigger i:nth-child(1) {
    -webkit-animation: outT 0.8s backwards;
    animation: outT 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger i:nth-child(2) {
    margin: 5px 0;
    -webkit-animation: outM 0.8s backwards;
    animation: outM 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger i:nth-child(3) {
    -webkit-animation: outBtm 0.8s backwards;
    animation: outBtm 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger.active i:nth-child(1) {
    -webkit-animation: inT 0.8s forwards;
    animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
    -webkit-animation: inM 0.8s forwards;
    animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
    -webkit-animation: inBtm 0.8s forwards;
    animation: inBtm 0.8s forwards;
}

@-webkit-keyframes inM {
    50% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(45deg);
    }
}

@keyframes inM {
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@-webkit-keyframes outM {
    50% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(45deg);
    }
}

@keyframes outM {
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@-webkit-keyframes inT {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(9px) rotate(135deg);
    }
}

@keyframes inT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(9px) rotate(0deg);
    }
    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@-webkit-keyframes outT {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(9px) rotate(135deg);
    }
}

@keyframes outT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(9px) rotate(0deg);
    }
    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@-webkit-keyframes inBtm {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(-9px) rotate(135deg);
    }
}

@keyframes inBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-9px) rotate(0deg);
    }
    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

@-webkit-keyframes outBtm {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(-9px) rotate(135deg);
    }
}

@keyframes outBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-9px) rotate(0deg);
    }
    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

.affix {
    padding: 0;
    background-color: #111;
}

@media screen and (max-width:768px) {
    .affix{
        padding-top: 20px;
    }
    
}




.myH3 {
	text-align:center;
	font-size: 4rem;
}
.myP {
	text-align: justify;
	padding-left:15%;
	padding-right:15%;
	font-size: 20px;
}
@media all and (max-width:700px){
	.myP {
		padding:2%;
	}
}

.home {
    width: 100%;
    height: 100vh;
    background-image: url("../public/img/OGLAS-ZONA-4.gif");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex; /* Add this property */
    align-items: stretch; /* Add this property */
}


#social-icons {
    display: flex;
    padding-left: 0;
  }
  
  #social-icons li {
    list-style: none;
  }
  
  #social-icons li a {
    width: 60px;
    height: 60px;
    background-color: #fff; /* Start with a white background */
    text-align: center;
    line-height: 60px;
    font-size: 25px;
    margin: 0 10px;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid #fff;
    z-index: 1;
  }
  
  #social-icons li a .icon {
    position: relative;
    color: #262626;
    transition: transform 0.5s;
    z-index: 3;
  }
  
  #social-icons li a:hover {
    background-color: transparent; /* Change background color to transparent on hover */
  }
  
  #social-icons li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
  }
  
  #social-icons li a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: .5s;
    z-index: 2;
  }
  
  #social-icons li a:hover:before {
    top: 100%;
  }
  
  #social-icons li:nth-child(1) a:before {
    background: #3b5999;
  }
  
  #social-icons li:nth-child(2) a:before {
    background: #E1306C;
  }
  
  #social-icons li:nth-child(3) a:before {
    background: #0077b5;
  }
  
  #social-icons li:nth-child(4) a:before {
    background: #dd4b39;
  }

  @media screen and (max-width:768px) {
    #social-icons {
    padding-bottom: 20px;
    }
  }

  .noblue{
    color: white;
    text-decoration: none;
  }


/* MailForm.css */

.mail-form {
    max-width: 800px;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    padding-top: 3px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 10px;
    font-size: 22px; /* Larger font size for labels */
  }
  
  .form-input,
  .form-textarea {
    width: 100%;
    padding: 12px; /* Larger padding for input fields */
    margin-bottom: 15px; /* Larger margin for input fields */
    border: 2px solid #ccc; /* Thicker border for input fields */
    border-radius: 8px; /* Larger border radius for input fields */
    box-sizing: border-box;
    font-size: 18px; /* Larger font size for input fields */
  }
  
  .form-textarea {
    resize: vertical;
  }
  
  .form-button {
    background-color: #4CAF50;
    color: white;
    padding: 15px 20px; /* Larger padding for the button */
    border: none;
    border-radius: 8px; /* Larger border radius for the button */
    cursor: pointer;
    font-size: 20px; /* Larger font size for the button */
  }
  
  .form-button:hover {
    background-color: #45a049;
  }

  .kontaktirajte{
    margin-top: 40px;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 3rem;
    text-align: center;
  }
  

  /* PRICING */

  @import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);
.pricing4 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.pricing4 h1,
.pricing4 h2,
.pricing4 h3,
.pricing4 h4,
.pricing4 h5,
.pricing4 h6 {
  color: #3e4555;
}

.pricing4 .font-weight-medium {
  font-weight: 500;
}


.pricing4 .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.pricing4 .font-14 {
  font-size: 14px;
}

.pricing4 .font-13 {
  font-size: 13px;
}

.pricing4 .card.card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.pricing4 .text-success {
  color: #2cdd9b !important;
}

.pricing4 .price small {
  color: #8d97ad;
  font-size: 16px;
}

.pricing4 h5 {
    line-height: 22px;
    font-size: 18px;
}

.pricing4 .btn-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0%, #ff6a5b 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
}

 .pricing4 .btn-danger-gradiant:hover {
  background: #e06c61;
  background: -webkit-linear-gradient(legacy-direction(to right), #ff6a5b 0%, #ff4d7e 100%);
  background: -webkit-gradient(linear, left top, right top, from(#ff6a5b), to(#ff4d7e));
  background: -webkit-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: -o-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: linear-gradient(to right, #ff6a5b 0%, #ff4d7e 100%);
}

.pricing4 .btn-md {
    padding: 10px 25px;
    font-size: 16px;
}


@media screen and (min-width: 768px) and (max-width: 1200px) {
    .pricing4 .col-md-4 .card{
        min-height: 500px;
    } 
}

@media screen and (min-width: 1200px) {
    .pricing4 .col-md-4 .card{
        min-height: 490px;
    } 
}

.pricing4 .card {
    padding-bottom: 10px;
}

.search-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 300px;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .search-input::placeholder {
    color: #666; /* Adjust color to make it more visible */
    opacity: 0.8; /* Adjust opacity to make it more visible */
  }
  
  





































  