.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000; /* You can change this to any background color */
    z-index: 9999; /* Ensure the splash screen is on top of everything */
  }
  